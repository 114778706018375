
export const environment = {
  production: false,
  UserPoolId: 'eu-west-2_yYLA6pT2C',
  ClientId: '1mu8gnsphidvtk9a2bornmsenk',
  baseUrl: 'https://13xjxw8o4b.execute-api.eu-west-2.amazonaws.com/dev/',
  stage: 'dev',
  region: 'eu-west-2',
  bucketUrl: 'https://touch-reports-dev.media.s3.eu-west-2.amazonaws.com'
};
